/*
 * @Descripttion: 注册API
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-30 18:38:27
 * @LastEditTime: 2021-10-09 18:46:10
 */
const url = 'index/Register/'
import {
    $get,
    $post
} from "@/utils" 

/**
 * 验证码
 *  @param {array} params 请求参数
 */
 
  //获取注册验证码
export let getCaptcha = function (params) {
    return $get(url + 'captcha',params)
}

  //注册
export let register = function (params) {
    return $post(url + 'register',params)
}  
//找回密码
export let forgetPwd = function (params) {
    return $post(url + 'forgetPwd',params)
}