<!--
 * @Descripttion: 忘记密码
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-10-09 15:18:01
 * @LastEditTime: 2021-11-25 13:32:41
-->
<template>
  <div>
    <Header />
    <div class="contentDiv">
      <el-form ref="forgerPwdFrom"
               :model="form"
               :rules="rules"
               class="login-box">
        <h3 class="login-title">忘记密码</h3>
        <el-form-item prop="phone">
          <el-input type="text"
                    prefix-icon="el-icon-mobile-phone"
                    placeholder="请输入手机号码"
                    v-model="form.phone" />
        </el-form-item>

        <el-form-item v-if="isCaptcha"
                      prop="captcha_code">
          <el-input type="text"
                    style="float:left;margin-right: -100px;"
                    placeholder="请输入验证码"
                    v-model="form.captcha_code" />
          <button type='button' v-if="btnTitle"
                  @click="getCaptcha"
                  style="width: 99px;float: left;height: 38px;margin: 1px 1px 1px 0px;position: relative;border: #ffffff;"
                  :disabled="disabled">{{btnTitle}}</button>

        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入密码"
                    v-model="form.password" />
        </el-form-item>
        <el-form-item prop="password2">
          <el-input type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入确认密码"
                    v-model="form.password2" />
        </el-form-item>

        <el-form-item>
          <el-button :loading="loading"
                     type="danger"
                     v-on:click="onSubmit('forgerPwdFrom')">确认修改</el-button>
          <router-link :to="{path:'login'}"
                       :underline="false"
                       style="margin-left:50px; color:#6e6e6e;font-size: 14px; text-decoration: auto;"
                       type="info">前往登录</router-link>

        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import Header from "../../components/Header.vue";
import { getCaptcha, forgetPwd } from "@/api/register";
import "@/styles/login.css";
export default {
  name: "DbgamewebForgetpwd",
  components: { Header },

  data() {
    return {
      btnTitle: "获取验证码",
      disabled: false, //是否可点击
      loading: false,
      isCaptcha: true,
      form: {
        password: "",
        password2: "",
        phone: "",
        captcha_code: "",
        captcha_id: "",
      },
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules: {
        phone: [{ required: true, message: "手机号不可为空", trigger: "blur" }],
        password: [
          { required: true, message: "密码不可为空", trigger: "blur" },
        ],
        password2: [
          { required: true, message: "确认密码不可为空", trigger: "blur" },
        ],
        captcha_code: [
          { required: true, message: "验证码不可为空", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {},

  methods: {
    onSubmit(formName) {
      if (this.validatePhone()) {
        // 为表单绑定验证功能
        this.$refs[formName].validate((valid) => {
          this.loading = true;
          if (valid) {
            if (this.form.password != this.form.password2) {
              this.$message.error({
                message: "输入的两次密码不一致",
                center: true,
              });
              this.loading = false;
              return false;
            }

            // 使用 vue-router 路由到指定页面，该方式称之为编程式导航
            //this.login();

            forgetPwd(this.form)
              .then(() => {
                delete this.form["password2"];
                delete this.form["password"];

                this.$message({
                  showClose: true,
                  message: "密码修改成功",
                  type: "success",
                });

                this.$router.push({ path: "/login" });
              })
              .catch(() => {
                delete this.form["captcha_code"];

                this.loading = false;
              });
          } else {
            //this.$message.error({ message: "输入账号和密码", center: true });
            this.loading = false;
            return false;
          }
        });
      }
    },
    // 获取验证码
    getCaptcha() {
      if (this.validatePhone()) {
        this.validateBtn();
        let params = {
          phone: this.form.phone,
        };
        getCaptcha(params).then((res) => {
          this.form.captcha_id = res.data.captcha_id;
          this.isCaptcha = res.data.captcha_switch;
          this.captchaImg = res.data.captcha_src;
        });
      }
    },
    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    validatePhone() {
      //判断输入的手机号是否合法
      if (!this.form.phone) {
        this.$message.error({ message: "手机号码不能为空" });
        return false;
      } else if (!/^1[345678]\d{9}$/.test(this.form.phone)) {
        this.$message.error({ message: "请输入正确是手机号" });
        return false;
      } else {
        this.errors = {};
        return true;
      }
    },
  },
};
</script>

<style scoped>
</style>