<!--
 * @Descripttion: 通用头部
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-09 22:46:21
 * @LastEditTime: 2021-10-10 00:11:23
-->
<template>

  <div>

    <div class="titleDiv">
      <hr class="hr">
      <img src="~@/assets/index_logo.png" class="logo" id="titleText" />
      
      <hr class="hr">
    </div>
    <div class="login"
         router>
      <div v-if="isLogin==false" style="text-align: right;">
        <el-button size="medium"
                   type="primary"
                   @click="goto('/login')">登录</el-button>
        <el-button size="medium" @click="goto('/register')"
                   type="success">注册</el-button>
      </div>
      <div style="text-align: right;" v-if="isLogin"
           class="islogin">
        欢迎 {{memberInfo.username}}，<el-button type="text" @click="goto('/member/person/memberinfo')"
                   class="userinfo">个人中心</el-button>
        <el-button @click="logout"
                   type="text"
                   class="logout">退出</el-button>
      </div>
    </div>

    <div class="header" >
      <el-menu  router 
               :data="menuList"
               :default-active="this.$route.path"
              background-color="initial"
              text-color="black"
               mode="horizontal">
        <el-menu-item index="/"
                      >平台首页</el-menu-item>
                     
        <el-menu-item v-for="(item,indexx) in menuList"
                      :key="indexx"                     
                      :index="'/articleinfo/'+item.content_id">
          {{item.name}}
        </el-menu-item>
      </el-menu>

    </div>

  </div>
 
</template> 

<script>
import { getMenu } from "@/api";
export default {
  name: "DbgamewebHeader",
  /*components: { ComponentName },
    directives: { DirectiveName  },
*/
  data() {
    return {
      isLogin: false,
      memberInfo: [],
      activeIndex: "0",
      menuList: [],
    }; 
  },
  created() {},
  mounted() { 
      // console.log(this.$store.state)
      //     console.log(this.$store.state.user.memberInfo)
    if (this.$store.state.user.memberInfo) {
   let memberInfo = JSON.parse(this.$store.state.user.memberInfo);
    //let memberInfo = this.$store.state.user.memberInfo;

      this.isLogin = true;
      this.memberInfo = memberInfo;    
    } else {
      this.isLogin = false;
    }
    getMenu().then((res) => {
      //console.log(res.data.list);
      this.menuList = res.data.list;
    });
  },
  methods: {
    goto(url) {
      this.$router.push(url); 
    },
    logout() {
      this.$store
        .dispatch("user/logout", this.form)
        .then(() => {
          console.log("退出成功");
       

        if (!this.$store.state.user.memberInfo) {
          //this.$router.push({ path: "/" });
          location.reload();
        }
          //location.reload();
          // console.log(res)

          //this.loading = false;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.islogin {
  color: white;
  font-size: 15px;
  font-weight: bold;
  .userinfo {
    font-size: 15px;
    color: #ff6565;
  }
  .logout {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
